<template>
  <div>
    <div class="text-right mb-2 col-10 offset-1">
      <CButton color="primary" to="/tfw/partner/profile">Back</CButton>
    </div>
    <div>
      <iframe
        id="iframe"
        ref="iframe"
        :srcdoc="htmlString"
        onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
        style="height: 100vh; width: 100%; border: none; overflow: hidden"
      ></iframe>
    </div>
  </div>
</template>
<script>
import SLCProfile from "@/components/reusable/ProfilePage/SLCPage";
import DOHProfile from "@/components/reusable/ProfilePage/DOHPage";
import NHSProfile from "@/components/reusable/ProfilePage/NHSPage";

import { CONSTANT } from "@/helpers/helper";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { BASE_URL } from "@/service_urls";

Vue.component("SLCProfile", SLCProfile);
Vue.component("DOHProfile", DOHProfile);
Vue.component("NHSProfile", NHSProfile);
export default {
  data() {
    return {
      partnerInfo: null,
      htmlString: null,
    };
  },
  computed: {
    partnerPageURL() {
      const htmlUrl = this.$route.query.html;
      return`${BASE_URL}${htmlUrl?.replace(
            "/api/v1",
            ""
          )}`;
    },
  },
  mounted(){
    this.convertHTMLtoDoc(this.partnerPageURL)
  },
  methods:{
    ...mapActions(["getHTML"]),
    convertHTMLtoDoc(url){
      this.getHTML(url).then(result => {
        this.htmlString = result?.data
      });
    }
  }
};
</script>
